<template>
    <div class="headerTop">
        <!-- logo -->
        <img :src="settingInfo.reportUrl" alt="">
        <!-- <span></span> -->
    </div>
</template>

<script>
import {getSetting} from '@/api/settings'
export default {
    data() {
        return{
            settingInfo: ''
        }
    },
    methods: {
        async getSettingInfo() {
      const res = await getSetting();
    //   console.log(res);
      if(res.code === 200) {
        this.settingInfo = res.data;
        // sessionStorage.setItem('settingInfo', JSON.stringify(res.data))
      }
    }
    },
    created() {
        this.getSettingInfo();
    }
}
</script>

<style lang="scoped" lang="scss">
.headerTop {
    // height: 80px;
    // width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 20px;
    border-bottom:1px solid #DDDDDD;
    // background: #7D7D7D;


    span {
        display: block;
        height: 1px;
        width: 100%;
        background: #DDDDDD;
        padding: 0 10px;
    }
}

@media screen and (max-width: 2160PX)and (min-width: 751PX) {
    .headerTop {
        height: 80px;

        img {
            height: 60%;
        }
    }

}
@media screen and (max-width: 750PX) {
    .headerTop {
        height: 60px;

        img {
            height: 60%;
        }
    }
}
</style>